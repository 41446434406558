import React from 'react';

export const PlaySvg = () => (
  <svg
    viewBox="0 0 33.34 33.33"
    focusable="false"
    data-icon="reload"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M12.15,25.22V8.11l13.56,8.56ZM16.67,0A16.67,16.67,0,1,0,33.34,16.67,16.67,16.67,0,0,0,16.67,0Z" />
  </svg>
);

export const ReloadSvg = () => (
  <svg
    viewBox="0 0 33.34 33.33"
    focusable="false"
    data-icon="reload"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M16.67,7.14a9.51,9.51,0,0,1,6.06,2.17.79.79,0,1,1-1,1.22,8,8,0,1,0,2.87,6.41L23.17,18.3a.8.8,0,1,1-1.1-1.15h0l2.78-2.65a.73.73,0,0,1,.44-.21.74.74,0,0,1,.65.21l2.78,2.65a.79.79,0,0,1,0,1.12.78.78,0,0,1-1.12,0h0l-1.45-1.37a9.52,9.52,0,1,1-9.51-9.79m0-7.14A16.67,16.67,0,1,0,33.34,16.67,16.67,16.67,0,0,0,16.67,0" />
  </svg>
);

export const PauseSvg = () => (
  <svg
    viewBox="0 0 33.34 33.33"
    focusable="false"
    data-icon="reload"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M10,24H4V0h6V24z M20,24h-6V0h6V24z" />
  </svg>
);

export const DeleteSvg = () => (
  <svg width="15px" height="18px" viewBox="0 0 15 18">
    <g id="delete" data-name="Delete">
      <path
        fill="#333"
        d="M2.48,16.61A1.52,1.52,0,0,0,4,18H11a1.52,1.52,0,0,0,1.49-1.39l1.36-12.7H1.12Z"
      />
      <path
        fill="#333"
        d="M14.25.78H9.75A.77.77,0,0,0,9,0H6a.77.77,0,0,0-.75.78H.75A.77.77,0,0,0,0,1.57a.77.77,0,0,0,.75.78h13.5A.77.77,0,0,0,15,1.57.77.77,0,0,0,14.25.78Z"
      />
    </g>
  </svg>
);

export const EditSvg = () => (
  <svg width="19px" height="20px" viewBox="0 0 19.42 19.92">
    <g id="edit" data-name="Edit">
      <path
        fill="#333"
        d="M16,17.62a.86.86,0,0,1-.87.86H2.3a.86.86,0,0,1-.86-.86V4.77A.87.87,0,0,1,2.3,3.9h8.23V2.46H2.3A2.31,2.31,0,0,0,0,4.77V17.62a2.3,2.3,0,0,0,2.3,2.3H15.15a2.31,2.31,0,0,0,2.31-2.3V7.74H16Z"
      />
      <path
        fill="#333"
        d="M18.86,2,17.38.56a1.91,1.91,0,0,0-2.7,0L6.14,9.1A.68.68,0,0,0,6,9.38l-1.64,4.8a.72.72,0,0,0,.17.74.75.75,0,0,0,.51.21.59.59,0,0,0,.23,0L10,13.45a.68.68,0,0,0,.28-.17l8.54-8.54A1.9,1.9,0,0,0,18.86,2ZM9.42,12.15,6.16,13.26,7.27,10l7.21-7.2,2.15,2.14Zm8.43-8.43-.21.2L15.5,1.78l.2-.21a.49.49,0,0,1,.66,0l1.49,1.49A.47.47,0,0,1,17.85,3.72Z"
      />
    </g>
  </svg>
);

export const SaveSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g id="save" data-name="Save">
      <path
        fill="#333"
        d="M12.984 12.656l2.625-2.578 1.406 1.406-5.016 5.016-5.016-5.016 1.406-1.406 2.625 2.578v-9.656h1.969v9.656zM18.984 12h2.016v6.984q0 0.797-0.609 1.406t-1.406 0.609h-13.969q-0.797 0-1.406-0.609t-0.609-1.406v-6.984h2.016v6.984h13.969v-6.984z"
      />
    </g>
  </svg>
);
