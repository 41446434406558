import React from 'react';
import './App.css';
import './App.less';
import { observer } from 'mobx-react';
import RouterComponent from '@/components/routers';

function App() {
  return <RouterComponent />;
}

export default observer(App);
