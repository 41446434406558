import http from '@/apis';
import { LoginRequestDto } from '@/dto/users/LoginRequest.dto';
import { ResetPassword } from '@/dto/users/ResetPassword.dto';
import { ForgotPasswordDto } from '@/dto/users/ForgotPassword.dto';

class AuthenticateService {
  prefix: string = 'api/web/users';

  public async checkGoogleToken(token: string) {
    const result = await http.post(`${this.prefix}/check-google-token`, {
      token,
    });
    return result.data.result;
  }

  public async login(model: LoginRequestDto) {
    const result = await http.post(`${this.prefix}/login`, model);
    return result.data.result;
  }

  public async validateToken(token: string) {
    const result = await http.post(`${this.prefix}/check-token`, { token });
    return result.data.result;
  }

  public async forgotPassword(model: ForgotPasswordDto) {
    const result = await http.get(
      `${this.prefix}/forgot-password?email=${model.email}`
    );
    return result.data.result;
  }

  public async resetPassword(model: ResetPassword) {
    const result = await http.post(`${this.prefix}/reset-password`, model);
    return result.data.result;
  }
}
export default new AuthenticateService();
