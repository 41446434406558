import React from 'react';
import { StripeStoreContext } from '@/stores/stripe.store';
import StripeCard from '@/components/accounts/StripeCard';
import { Modal } from 'antd';

interface ComponentProps {
  visible: boolean;
}

const CustomFooter = React.memo((props: ComponentProps) => {
  const { visible } = props;
  const stripeStore = React.useContext(StripeStoreContext);

  const handleCancel = () => {
    stripeStore.resetCheckSubscription();
  };

  return (
    <>
      <Modal
        visible={visible}
        footer={null}
        onCancel={() => handleCancel()}
        className="modal-custom"
        // width={900}
      >
        <StripeCard className="form-wrapper form-grid profile-sidebar-card" />
      </Modal>
    </>
  );
});

export default CustomFooter;
