import { observable, action } from 'mobx';
import { createContext } from 'react';

export default class CommonStore {
  @observable isGGScriptLoaded: boolean = false;
  @observable isLoading: boolean = false;
  @observable timezone: string =
    process.env.REACT_APP_TIME_ZONE || 'America/Los_Angeles';

  @observable zones = {
    PST: {
      posfix: 'PST',
      value: -8,
    },
    NOPST: {
      posfix: '',
      value: -8,
    },
  };

  @observable timeFormat: string = 'MM/DD/YYYY HH:mm';
  @observable shortTimeFormat: string = 'DD MMM YYYY';

  @action
  setGGScriptLoadStatus = (status: boolean) => {
    this.isGGScriptLoaded = status;
  };

  @action
  setLoading = (status: boolean) => {
    this.isLoading = status;
  };
}

export const CommonStoreContext = createContext(new CommonStore());
