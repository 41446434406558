import React from 'react';

import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { getRouteTitle, isGuarded, isSecurePage } from '@/utils/routes.util';
import { observer } from 'mobx-react';
import { AuthenticationStoreContext } from '@/stores/authentication.store';
import { StripeStoreContext } from '@/stores/stripe.store';
import DefaultLayout from '@/components/layouts/Default';
import AdminLayout from '@/components/layouts/Admin';
import {
  saveToSession,
  removeFromStorage,
  retrieveFromSession,
} from '@/utils/storage.util';
import CustomFooter from '@/components/commons/CustomFooter';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

declare const document: any;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

const Router = () => {
  const location = useLocation();
  const authStore = React.useContext(AuthenticationStoreContext);
  const stripeStore = React.useContext(StripeStoreContext);
  const history = useHistory();
  React.useEffect(() => {
    if (location.pathname.includes('/account/verify-change-email')) {
      removeFromStorage('token');
    } else {
      const existedToken = localStorage.getItem('token');
      const existedNickname = retrieveFromSession('nickname');

      if (!existedToken && isGuarded(location.pathname)) {
        history.push('/account/login');
      }
      if (existedToken && !authStore.loggedUser) {
        authStore.validateToken(existedToken, history);
      }
      if (existedToken && isSecurePage(location.pathname)) {
        history.push('/account');
      }
      if (
        existedToken &&
        authStore.loggedUser &&
        authStore.loggedUser?.userType === null &&
        authStore?.loggedUser?.role < 1
      ) {
        if (authStore.loggedUser?.accountType === 1 && !existedNickname) {
          return history.push('/account/register');
        } else {
          return history.push('/account/register');
        }
      }
      if (existedToken && authStore.loggedUser && location.pathname === '/') {
        history.push('/account');
      }
      const checkSubscription = retrieveFromSession('checkSubscription');
      if (checkSubscription) {
        stripeStore.setCheckSubscription(true);
      }
    }
  }, [
    authStore,
    authStore.loggedUser,
    history,
    location.pathname,
    stripeStore,
  ]);

  React.useEffect(() => {
    const title = getRouteTitle(location.pathname);
    document.title = title;
  });

  return (
    <>
      <Elements stripe={stripePromise}>
        <Switch>
          {authStore?.loggedUser != null && authStore?.loggedUser?.role > 0 && (
            <Route
              path="/admin/*"
              render={(props: any) => <AdminLayout {...props} exact />}
            />
          )}
          <Route
            path="/"
            render={(props: any) => <DefaultLayout {...props} exact />}
          />
        </Switch>
        <CustomFooter visible={stripeStore.checkSubscription} />
      </Elements>
    </>
  );
};

export default observer(Router);
