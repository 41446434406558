import React from 'react';
import HeaderDefault from '@/components/commons/Header';
import { Layout, Col } from 'antd';
import './index.less';

interface WrapperProps {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  header?: boolean;
}

const Wrapper: React.FC<WrapperProps> = (props) => {
  const { style, className, children, header = false } = props;
  const styleLayout = style ? style : {};
  const clsLayout = className ? 'page-view ' + className : 'page-view';

  return (
    <Layout style={styleLayout} className={clsLayout}>
      <Col className="container">
        {header && <HeaderDefault />}
        {children}
      </Col>
    </Layout>
  );
};

export default Wrapper;
