import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './index.less';

const LoadingOverlap = () => {
  return (
    <>
      <div className="inner-loading">
        <LoadingOutlined />
      </div>
    </>
  );
};

export default LoadingOverlap;
