import http from '@/apis';
import { prepareGetQuery } from '@/utils/routes.util';

class StripeService {
  prefix: string = 'api/stripe';
  userPrefix: string = 'api/web/users';

  public async getPrices(productId?: string) {
    const query = prepareGetQuery({ productId });
    const result = await http.get(`${this.prefix}/prices${query}`);
    return result.data.result;
  }

  public async updatePaymentMethod(model: any, userId: number) {
    const result = await http.put(
      `${this.userPrefix}/${userId}/update-payment-method`,
      {
        paymentMethodId: model.id,
        last4: model.card.last4,
        exp_month: model.card.exp_month,
        exp_year: model.card.exp_year,
      }
    );
    return result.data.result;
  }

  public async createSubscription(priceId: string, metadata: any) {
    const result = await http.post(`${this.userPrefix}/subscription`, {
      priceId,
      metadata,
    });
    return result?.data?.result ? result?.data?.result : result;
  }

  /*
   * cancel all subscriptions
   *
   * @return any
   */
  public async cancelAllSubscription() {
    const result = await http.post(
      `${this.userPrefix}/subscription/stripe-cancel-all`
    );
    return result.data.result;
  }

  /*
   * detach stripe payment methods
   *
   * @return any
   */
  public async detachStripePaymentMethods() {
    const result = await http.post(
      `${this.userPrefix}/payment-method/stripe-detach-all`
    );
    return result.data.result;
  }

  /*
   * get a list of payment methods
   *
   * @return any
   */
  public async getPaymentMethods() {
    const result = await http.get(`${this.userPrefix}/payment-method`);
    return result.data.result;
  }

  /*
   * Delete an payment
   *
   * @param string id
   *
   * @return any
   */
  public async deletePaymentMethod(id: string) {
    const result = await http.delete(`${this.userPrefix}/payment-method/${id}`);
    return result.data.result;
  }

  /*
   * Get subscription Information
   *
   * @return any
   */
  public async getLatestSubscription() {
    const result = await http.get(`${this.userPrefix}/subscription-status`);
    return result.data.result;
  }

  /*
   * change status of auto renewal
   *
   * @param boolean status
   *
   * @return any
   */
  public async changeAutoRenewalStatus(status: boolean) {
    const result = await http.put(`${this.userPrefix}/auto-renewal`, {
      status,
    });
    return result.data.result;
  }
}
export default new StripeService();
