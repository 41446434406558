import { defaultRoutes, adminRoutes } from '@/components/routers/routes';

const allRoutes = [...defaultRoutes, ...adminRoutes];

export const getRouteTitle = (path: string) => {
  return (
    allRoutes.find((route) => route.path === path)?.title ||
    process.env.REACT_APP_DEFAULT_TITLE
  );
};

export const prepareGetQuery = (filter: Record<string, unknown>) => {
  const keys = Object.keys(filter);
  const filterValues = keys
    .filter((key) => !!filter[key] || filter[key] === 0)
    .map((key) => `${key}=${filter[key]}`);

  const behind = filterValues.join('&');

  if (behind) return `?${behind}`;

  return '';
};

export const isGuarded = (path: string): boolean => {
  return allRoutes.find((route) => route.path === path)?.isGuarded === true
    ? true
    : false;
};

export const isStaticPage = (path: string): boolean => {
  const routeItem = allRoutes.find((route) => route.path === path);
  return routeItem?.isGuarded !== true && routeItem?.isGuarded !== false
    ? true
    : false;
};

export const isSecurePage = (path: string): boolean => {
  return allRoutes.find((route) => route.path === path)?.isGuarded === false
    ? true
    : false;
};
