import React from 'react';
import { observer } from 'mobx-react';
import { AuthenticationStoreContext } from '@/stores/authentication.store';
import logo from '@/assets/images/logo.svg';
import './index.less';
import { useHistory } from 'react-router-dom';
// import { RadioSvg } from '@/components/commons/FontIcon';
import { Layout, Menu, Avatar, Typography } from 'antd';
import { loginMenu, loggedMenu } from '@/components/routers/routes';
import profileImage from '@/assets/images/emoji/schedule-lonny-emoji.png';

const { Header } = Layout;
const { SubMenu } = Menu;
const { Link } = Typography;

// interface ComponentProps {}

const HeaderDefault = () => {
  const history = useHistory();
  const authStore = React.useContext(AuthenticationStoreContext);
  const headerMenu: any[] = !authStore.loggedUser ? loginMenu : loggedMenu;

  const handleSubMenu = (link: string) => {
    history.push(link);
  };

  return (
    <>
      <Header className="page-header">
        <div className="logo">
          <Link onClick={() => history.push('/')} title="Llama App">
            <img src={logo} alt="Llama App" />
          </Link>
        </div>
        <Menu mode="horizontal">
          {headerMenu.map((item, index) => {
            if (item.subs.length > 0) {
              if (item.name === 'dashboard') {
                return (
                  <SubMenu
                    key={`menu-item-${index}`}
                    title={
                      <span className="profile-logo">
                        <Avatar
                          src={
                            authStore.loggedUser?.avatarURL
                              ? authStore.loggedUser?.avatarURL
                              : profileImage
                          }
                        />
                      </span>
                    }
                    className={item.className}
                    onTitleClick={() => handleSubMenu(item.path)}
                  >
                    {(item.subs as any[]).map((subitem, index) => {
                      return (
                        <Menu.Item
                          key={`sub-item-${index}`}
                          onClick={() => history.push(subitem.path)}
                          className={subitem.className}
                        >
                          {subitem.title}
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );
              } else {
                return (
                  <SubMenu
                    key={item.name}
                    title={item.title}
                    className={item.className}
                    onTitleClick={() => handleSubMenu(item.path)}
                  >
                    {(item.subs as any[]).map((subitem) => {
                      return (
                        <Menu.Item
                          key={subitem.path}
                          onClick={() => history.push(subitem.path)}
                          className={subitem.className}
                        >
                          {subitem.title}
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );
              }
            } else if (item.path) {
              return (
                <Menu.Item
                  key={item.path}
                  onClick={() => history.push(item.path)}
                  className={item.className}
                >
                  {item.title}
                </Menu.Item>
              );
            }

            return <></>;
          })}
        </Menu>
      </Header>
    </>
  );
};

export default observer(HeaderDefault);
