import CommonStore from './common.store';
import AuthenticationStore from './authentication.store';
import VideoStore from './video.store';

export default function initStore() {
  return {
    commonStore: new CommonStore(),
    authStore: new AuthenticationStore(),
    videoStore: new VideoStore(),
  };
}
