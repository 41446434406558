import React from 'react';
import { Typography } from 'antd';
import SloganImage from '@/assets/images/slogan.png';
import { Row, Col } from 'antd';
import Wrapper from '@/components/commons/Wrapper';
import LonnyRun from '@/assets/images/lonny-run.png';
import './index.less';

const { Title } = Typography;
const Loading = () => {
  return (
    <>
      <Wrapper header={true}>
        <Row justify="center" className="area-loading-wrapper main">
          <Col span={15}>
            <div className="area-loading">
              <div className="box-title-image mb-5">
                <img src={SloganImage} alt="Slogan Lonny" />
              </div>
              <Title className="area-title" level={3}>
                Loading ...
              </Title>
            </div>
          </Col>
          <Col span={9}>
            <div className="box-image">
              <img src={LonnyRun} alt="Lonny" />
            </div>
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};

export default Loading;
