import React from 'react';
import {
  UserOutlined,
  QuestionCircleOutlined,
  DollarCircleOutlined,
  // BookOutlined,
} from '@ant-design/icons';

export const loginMenu = [
  {
    path: '/account/login',
    name: 'account.login',
    title: 'Login',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/Login')),
    isLayout: true,
    isGuarded: true,
    subs: [],
    className: '',
  },
  {
    path: '/account/register',
    name: 'account.register',
    title: 'Register',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/components/accounts/Register')),
    isLayout: true,
    isGuarded: true,
    subs: [],
    className: '',
  },
];

const lazyQuestionnaireList = React.lazy(
  () => import('@/pages/accounts/questionnaire/List')
);

export const loggedMenu = [
  {
    path: '/account',
    name: 'account',
    title: 'Home',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/Account')),
    isLayout: true,
    isGuarded: true,
    subs: [],
    className: '',
  },
  {
    path: '/questionnaire/manage',
    name: 'questionnaire',
    title: 'Adventures',
    className: '',
    subs: [
      {
        path: '/questionnaire/manage/completed',
        name: 'questionnaire.completed',
        title: 'Completed',
        exact: true,
        permission: '',
        component: lazyQuestionnaireList,
        isLayout: false,
        isGuarded: true,
        className: 'icon-iklama icon-complete',
      },
      {
        path: '/questionnaire/manage/inprogress',
        name: 'questionnaire.inprogress',
        title: 'In Process',
        exact: true,
        permission: '',
        component: React.lazy(
          () => import('@/pages/accounts/questionnaire/List')
        ),
        isLayout: false,
        isGuarded: true,
        className: 'icon-iklama icon-inprocess',
      },
    ],
  },
  {
    path: '/checklist',
    name: 'checklist',
    title: 'My CheckList',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/Checklist')),
    isLayout: true,
    isGuarded: true,
    subs: [],
    className: '',
  },
  {
    path: '/script',
    name: 'script',
    title: 'My Script',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/Script')),
    isLayout: true,
    isGuarded: true,
    subs: [],
    className: '',
  },
  {
    path: '/videos',
    name: 'video',
    title: 'My Videos',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/Videos')),
    isLayout: true,
    isGuarded: true,
    subs: [],
    className: '',
  },

  // {
  //   path: '/roadmap',
  //   name: 'roadmap',
  //   title: 'Roadmap',
  //   exact: true,
  //   permission: '',
  //   component: React.lazy(() => import('@/pages/accounts/Roadmap')),
  //   isLayout: true,
  //   isGuarded: true,
  //   subs: [],
  //   className: '',
  // },
  {
    path: '/profile',
    name: 'dashboard',
    title: 'Account',
    haveSub: true,
    className: 'menu-item-account',
    subs: [
      {
        path: '/profile',
        name: 'profile',
        title: 'Profile',
        exact: true,
        permission: '',
        component: React.lazy(() => import('@/pages/accounts/Profile')),
        isLayout: false,
        isGuarded: true,
        className: 'icon-iklama icon-profile',
      },
      {
        path: '/about',
        name: 'about',
        title: 'About Us',
        exact: true,
        permission: '',
        component: React.lazy(() => import('@/pages/default/About')),
        isLayout: false,
        isGuarded: true,
        className: 'icon-iklama icon-about',
      },
      {
        path: '/setting',
        name: 'setting',
        title: 'Settings',
        exact: true,
        permission: '',
        component: React.lazy(() => import('@/pages/accounts/Profile')),
        isLayout: false,
        isGuarded: true,
        className: 'icon-iklama icon-settings',
      },
    ],
  },
];

export const defaultRoutes = [
  {
    path: '/',
    name: 'splash',
    title: 'The Llama App',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/default/Splash')),
    isLayout: false,
  },
  {
    path: '/onboarding',
    name: 'onboard',
    title: 'On Boarding',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/default/OnBoarding')),
    isLayout: false,
    isGuarded: false,
  },
  {
    path: '/welcome/index',
    name: 'welcome.index',
    title: 'Welcome',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/default/welcome/Index')),
    isLayout: false,
  },
  {
    path: '/welcome/name',
    name: 'welcome.name',
    title: 'What do your friends Call you?',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/default/welcome/EnterName')),
    isLayout: false,
  },
  {
    path: '/disclaimer-terms',
    name: 'disclaimer.terms',
    title: 'Legal Disclaimer',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/default/TermsofService')),
    isLayout: false,
  },
  {
    path: '/privacy-policy',
    name: 'privacy.policy',
    title: 'Privacy Policy',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/default/PrivacyPolicy')),
    isLayout: false,
  },
  {
    path: '/no-route',
    name: 'no.route',
    title: 'Page Not Found',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/PageNotFound')),
    isLayout: false,
  },
  {
    path: '/error',
    name: 'error',
    title: 'Error',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/default/Error')),
    isLayout: false,
  },
  {
    path: '/ui',
    name: 'ui.ux',
    title: 'Style Guide',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/default/ui')),
    isLayout: false,
  },
  {
    path: '/account/login',
    name: 'account.login',
    title: 'Login - Account',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/Login')),
    isLayout: false,
    isGuarded: false,
  },
  {
    path: '/account/forgot-password',
    name: 'account.forgot',
    title: 'Forgot Password - Account',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/ForgotPassword')),
    isLayout: false,
    isGuarded: false,
  },
  {
    path: '/account/register',
    name: 'account.register',
    title: 'Register - Account',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/Register')),
    isLayout: false,
    isGuarded: false,
  },
  {
    path: '/account/register/success',
    name: 'account.register.success',
    title: 'Register Successful - Account',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/RegisterSuccess')),
    isLayout: false,
    isGuarded: false,
  },
  {
    path: '/account/verify-email/:token',
    name: 'account.verify',
    title: 'Verify - Account',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/VerifyEmail')),
    isLayout: false,
    isGuarded: false,
  },
  {
    path: '/account/verified-email',
    name: 'account.verified',
    title: 'Verified Email - Account',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/VerifiedEmail')),
    isLayout: false,
    isGuarded: false,
  },
  {
    path: '/account/verify-change-email/:token',
    name: 'account.verify',
    title: 'Verify - Account',
    exact: true,
    permission: '',
    component: React.lazy(
      () => import('@/components/accounts/VerifyChangeEmail')
    ),
    isLayout: false,
    isGuarded: false,
  },
  {
    path: '/account/reset-password/:token',
    name: 'account.reset-password',
    title: 'Reset Password - Account',
    exact: true,
    component: React.lazy(() => import('@/pages/accounts/ResetPassword')),
    isLayout: false,
    isGuarded: false,
  },
  {
    path: '/account',
    name: 'account',
    title: 'Account',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/Account')),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/profile',
    name: 'profile',
    title: 'Profile',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/Profile')),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    title: 'Adventure',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/questionnaire/Home')),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/questionnaire/conflict',
    name: 'questionnaire.conflict',
    title: 'Adventure - What is a conflict?',
    exact: true,
    permission: '',
    component: React.lazy(
      () => import('@/pages/accounts/questionnaire/Conflict')
    ),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/questionnaire/list',
    name: 'questionnaire.list',
    title: 'Adventures',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/questionnaire/List')),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/questionnaire/completed',
    name: 'questionnaire.completed',
    title: 'Adventure Completed',
    exact: true,
    permission: '',
    component: React.lazy(
      () => import('@/pages/accounts/questionnaire/Completed')
    ),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/questionnaire/completed/:id',
    name: 'questionnaire.completed.detail',
    title: 'Adventure Completed Detail',
    exact: true,
    permission: '',
    component: React.lazy(
      () => import('@/pages/accounts/questionnaire/CompletedDetail')
    ),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/questionnaire/view/:id',
    name: 'questionnaire.view',
    title: 'Adventure View',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/questionnaire/View')),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/questionnaire/manage',
    name: 'questionnaire.manage',
    title: 'Adventure Manage',
    exact: true,
    permission: '',
    component: React.lazy(
      () => import('@/pages/accounts/questionnaire/Manage')
    ),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/questionnaire/manage/:status',
    name: 'questionnaire.manage',
    title: 'Adventure Manage',
    exact: true,
    permission: '',
    component: React.lazy(
      () => import('@/pages/accounts/questionnaire/Manage')
    ),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/questionnaire/:id/step/:step',
    name: 'questionnaire.detail',
    title: 'Adventure Detail',
    exact: true,
    permission: '',
    component: React.lazy(
      () => import('@/pages/accounts/questionnaire/Detail')
    ),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/questionnaire/:id',
    name: 'questionnaire.detail',
    title: 'Adventure Detail',
    exact: true,
    permission: '',
    component: React.lazy(
      () => import('@/pages/accounts/questionnaire/Detail')
    ),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/questionnaire/:id/roadmap',
    name: 'questionnaire.detail',
    title: 'Roadmap Detail',
    exact: true,
    permission: '',
    component: React.lazy(
      () => import('@/pages/accounts/questionnaire/Roadmap')
    ),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/checklist',
    name: 'checklist',
    title: 'My CheckList',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/Checklist')),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/checklist/:id',
    name: 'checklist.detail',
    title: 'CheckList Detail',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/ChecklistDetail')),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/script',
    name: 'script',
    title: 'My Script',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/Script')),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/videos',
    name: 'videos',
    title: 'My Videos',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/Videos')),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/videos/all',
    name: 'video',
    title: 'My Videos',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/VideoAll')),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/script/:id',
    name: 'script.detail',
    title: 'Script Detail',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/ScriptDetail')),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/roadmap',
    name: 'roadmap',
    title: 'Roadmap',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/Roadmap')),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/roadmap:id',
    name: 'roadmap.detail',
    title: 'Roadmap Detail',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/RoadmapDetail')),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/about',
    name: 'about',
    title: 'About Us',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/default/About')),
    isLayout: false,
    isGuarded: true,
  },
  {
    path: '/setting',
    name: 'setting',
    title: 'Settings',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/accounts/Profile')),
    isLayout: false,
    isGuarded: true,
  },
];

export const adminRoutes = [
  {
    path: '/admin',
    name: 'account',
    title: 'Admin',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/components/layouts/Admin')),
    isLayout: true,
    isMenu: false,
    isGuarded: true,
  },
  // {
  //   path: '/admin/guide',
  //   name: 'admin.guide',
  //   title: 'Guide',
  //   exact: true,
  //   permission: '',
  //   component: React.lazy(() => import('@/pages/admin/Guide')),
  //   isLayout: false,
  //   isMenu: true,
  //   icon: <BookOutlined />,
  //   isGuarded: true,
  // },
  {
    path: '/admin/user/:userId',
    name: 'admin.user.detail',
    title: 'Admin-User-Detail',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/admin/user/Detail')),
    isLayout: false,
    isMenu: false,
    icon: <UserOutlined />,
    isGuarded: true,
  },
  {
    path: '/admin/user',
    name: 'admin.user',
    title: 'Users',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/admin/user/List')),
    isLayout: false,
    isMenu: true,
    icon: <UserOutlined />,
    isGuarded: true,
  },

  {
    path: '/admin/subscription',
    name: 'admin.subscription',
    title: 'Subscriptions',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/admin/Subscription')),
    isLayout: false,
    isMenu: true,
    icon: <DollarCircleOutlined />,
    isGuarded: true,
  },
  {
    path: '/admin/question',
    name: 'admin.question',
    title: 'Adventures',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/admin/question/List')),
    isLayout: false,
    isMenu: true,
    icon: <QuestionCircleOutlined />,
    isGuarded: true,
  },
  {
    path: '/admin/questionnaire/:id',
    name: 'admin.question',
    title: 'Admin-Question',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/admin/tree/TreeView')),
    isLayout: false,
    isMenu: false,
    icon: <QuestionCircleOutlined />,
    isGuarded: true,
  },
  {
    path: '/admin/content',
    name: 'admin.content',
    title: 'Contents',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/admin/content/List')),
    isLayout: false,
    isMenu: true,
    icon: <QuestionCircleOutlined />,
    isGuarded: true,
  },
  {
    path: '/admin/video',
    name: 'admin.video',
    title: 'Videos',
    exact: true,
    permission: '',
    component: React.lazy(() => import('@/pages/admin/video/List')),
    isLayout: false,
    isMenu: true,
    icon: <QuestionCircleOutlined />,
    isGuarded: true,
  },
];
