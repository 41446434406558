import http from '@/apis';
import CreateVideoDto from '@/dto/videos/CreateVideo.dto';
import { prepareGetQuery } from '@/utils/routes.util';

class VideoService {
  adminPrefix: string = 'api/admin/videos';
  webPrefix: string = 'api/web/videos';

  async adminGetVideos(filter: Record<string, unknown>) {
    const queryString = prepareGetQuery(filter);
    const result = await http.get(`${this.adminPrefix}${queryString}`);
    return result.data.result;
  }

  async createVideo(model: CreateVideoDto) {
    const form = new FormData();

    Object.keys(model).forEach((key) => {
      form.append(key, (model as any)[key]);
    });
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const result = await http.post(`${this.adminPrefix}`, form, config);
    return result.data.result;
  }

  async updateIsActive(id: string, isActive: boolean) {
    const result = await http.put(`${this.adminPrefix}/${id}/is-active`, {
      status: isActive,
    });
    return result.data.result;
  }

  async updateIsFeatured(id: string, isFeatured: boolean) {
    const result = await http.put(`${this.adminPrefix}/${id}/is-featured`, {
      status: isFeatured,
    });
    return result.data.result;
  }

  async delete(id: string) {
    const result = await http.delete(`${this.adminPrefix}/${id}`);
    return result.data.result;
  }

  async update(id: string, model: CreateVideoDto) {
    const form = new FormData();

    Object.keys(model).forEach((key) => {
      form.append(key, (model as any)[key]);
    });
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const result = await http.put(`${this.adminPrefix}/${id}`, form, config);
    return result.data.result;
  }

  async userGetVideos() {
    const result = await http.get(`${this.webPrefix}`);
    return result.data.result;
  }

  async userGetInitVideos() {
    const result = await http.get(`${this.webPrefix}/init`);
    return result.data.result;
  }

  async userGetOneVideo(id: string) {
    const result = await http.get(`${this.webPrefix}/${id}`);
    return result.data.result;
  }
}
export default new VideoService();
