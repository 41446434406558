import React from 'react';
import { useHistory } from 'react-router-dom';
import Wrapper from '@/components/commons/Wrapper';
import { Typography, Row, Col } from 'antd';
import bannerImage from '@/assets/images/bkg/bkg-onboarding.jpg';
import './index.less';

export default function PageNotFound() {
  const history = useHistory();
  const { Paragraph } = Typography;
  const bkgImage = {
    backgroundImage: `url(${bannerImage})`,
  };

  React.useEffect(() => {
    // if (localStorage.getItem('token')) {
    //   return history.push('/profile');
    // }
    // return history.push('account/login');
  }, [history]);

  return (
    <>
      <Wrapper className="has-bkg" style={bkgImage} header={true}>
        <Row justify="center" className="area-std-wrapper main">
          <Col span={24} className="area-std area-fix mb-6">
            <Typography>
              <Paragraph
                className="txt-center"
                style={{ padding: '200px 0 0' }}
              >
                Page Not Found
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </Wrapper>
    </>
  );
}
