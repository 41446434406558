import * as firebase from 'firebase/app';
import 'firebase/auth';

class FireBase {
  firebaseInstance: any;
  provider: any;
  constructor() {
    const options = {
      apiKey: process.env.REACT_APP_FIREBASE_KEY,
      authDomain: 'lonnie-the-llama.firebaseapp.com',
      databaseURL: 'https://lonnie-the-llama.firebaseio.com',
      projectId: 'lonnie-the-llama',
      storageBucket: 'lonnie-the-llama.appspot.com',
      messagingSenderId: '402644252325',
      appId: '1:402644252325:web:7844fc461390b95bb53f4c',
      measurementId: 'G-3N1BL88Y6B',
    };
    this.firebaseInstance = firebase.initializeApp(options);
    this.provider = new firebase.auth.GoogleAuthProvider();
  }

  async login() {
    await firebase.auth().signInWithPopup(this.provider);
    return await firebase.auth().currentUser?.getIdToken();
  }

  async logout() {
    await firebase.auth().signOut();
  }

  async createCredential(token: string) {
    await firebase.auth.GoogleAuthProvider.credential(token);
  }
}

export default new FireBase();
