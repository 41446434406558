import { observable, action } from 'mobx';
import { createContext } from 'react';
import stripeService from '@/apis/stripe.service';
import { removeFromSession } from '@/utils/storage.util';

export default class StripeStore {
  @observable prices: any[] = [];
  @observable payments: any[] = [];
  @observable renewal: boolean = false;
  @observable subscription: any = undefined;
  @observable checkSubscription: any = false;

  @action
  setCheckSubscription = async (status: boolean) => {
    this.checkSubscription = status;
  };

  @action
  resetCheckSubscription = async () => {
    this.checkSubscription = false;
    removeFromSession('checkSubscription');
  };

  @action
  getPrices = async (productId?: string) => {
    const data = await stripeService.getPrices(productId);
    data.data.sort((a: any, b: any) => {
      return a.unit_amount > b.unit_amount
        ? 1
        : a.unit_amount < b.unit_amount
        ? -1
        : 0;
    });
    this.prices = data.data;
  };

  @action
  updatePaymentMethod = async (model: any, userId: number) => {
    const data = await stripeService.updatePaymentMethod(model, userId);
    return data;
  };

  @action
  createSubscription = async (priceId: string, metadata: any) => {
    const data = await stripeService.createSubscription(priceId, metadata);
    return data;
  };

  /*
   * cancel all subscriptions
   *
   * @return any
   */
  @action
  public async cancelAllSubscription() {
    const data = await stripeService.cancelAllSubscription();
    return data;
  }

  /*
   * detach stripe payment methods
   *
   * @return any
   */
  @action
  public async detachStripePaymentMethods() {
    const data = await stripeService.detachStripePaymentMethods();
    return data;
  }

  /*
   * get a list of payment methods
   *
   * @return any
   */
  @action
  public async getPaymentMethods() {
    const data = await stripeService.getPaymentMethods();
    this.payments = data;
    return data;
  }

  /*
   * Delete an payment
   *
   * @param string id
   *
   * @return any
   */
  @action
  public async deletePaymentMethod(id: string) {
    const data = await stripeService.deletePaymentMethod(id);
    return data;
  }

  /*
   * Get subscription Information
   *
   * @return any
   */
  @action
  public async getLatestSubscription() {
    const data = await stripeService.getLatestSubscription();
    this.subscription = data;
    this.renewal = data?.autoRenewal;
    return data;
  }

  /*
   * change status of auto renewal
   *
   * @param boolean status
   *
   * @return any
   */
  @action
  public async changeAutoRenewalStatus(status: boolean) {
    const data = await stripeService.changeAutoRenewalStatus(status);
    return data;
  }
}

export const StripeStoreContext = createContext(new StripeStore());
