export const handleResponseError = (error: any) => {
  return Promise.reject(error);
};

export const removeConfirmationFields = (object: any) => {
  const keysWithConfirmation = Object.keys(object).filter((key) =>
    key.includes('confirm')
  );

  const excludedFiltered = { ...object };
  keysWithConfirmation.map((key) => delete excludedFiltered[key]);
  return excludedFiltered;
};
