import http from '@/apis';
import { prepareGetQuery } from '@/utils/routes.util';
import CreateUser from '@/dto/users/CreateUser.dto';
import UpdateUser from '@/dto/users/UpdateUser.dto';
import ChangePassword from '@/dto/users/ChangePassword.dto';
import UpdateUserInfo from '@/dto/users/UpdateUserInfo.dto';
import { removeConfirmationFields } from '@/utils/apis.util';

class UserService {
  adminPrefix: string = 'api/admin/users';
  userPrefix: string = 'api/web/users';

  async getUsers(filter: Record<string, unknown>) {
    const queryString = prepareGetQuery(filter);
    const result = await http.get(`${this.adminPrefix}${queryString}`);
    return result.data.result;
  }

  async validateEmail(token: string) {
    const result = await http.post(`${this.userPrefix}/verify-email`, {
      token,
    });
    return result.data.result;
  }

  async verifyChangeEmail(token: string) {
    const result = await http.post(`${this.userPrefix}/verify-change-email`, {
      token,
    });
    return result.data.result;
  }

  async adminCreateUser(model: CreateUser) {
    const result = await http.post(`${this.adminPrefix}`, model);
    return result.data.result;
  }

  async adminUpdateUser(model: UpdateUser, id: number) {
    const result = await http.put(`${this.adminPrefix}/${id}`, model);
    return result.data.result;
  }

  async adminDeleteUser(id: number) {
    const result = await http.delete(`${this.adminPrefix}/${id}`);
    return result.data.result;
  }

  async adminGetUserDetail(id: number) {
    const result = await http.get(`${this.adminPrefix}/${id}`);
    return result.data.result;
  }

  async register(model: CreateUser) {
    // const { ...excludedModel } = model;
    const excludedModel = removeConfirmationFields(model);
    const result = await http.post(`${this.userPrefix}`, excludedModel);
    return result.data.result;
  }

  async changePassword(id: number, model: ChangePassword) {
    const result = await http.post(
      `${this.userPrefix}/${id}/change-password`,
      model
    );
    return result.data.result;
  }

  async uploadAvatar(id: number, data: any) {
    const form = new FormData();

    form.append('image', data.file);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await http.post(
      `${this.userPrefix}/${id}/upload-profile-image`,
      form,
      config
    );
    return result.data.result;
  }

  async requestChangeEmail(id: number, email: string) {
    const result = await http.put(`${this.userPrefix}/${id}/change-email`, {
      email,
    });

    return result.data.result;
  }

  /*
   * update first name, last name and user type
   *
   * @param number id
   * @param UpdateUserInfo model
   *
   * @return any
   */
  async requestUpdateUserInfo(id: number, model: UpdateUserInfo) {
    const result = await http.put(`${this.userPrefix}/${id}`, model);
    return result.data.result;
  }

  async setAutoplayStatus(status: boolean) {
    const result = await http.put(`${this.userPrefix}/auto-play`, {
      status,
    });
    return result.data.result;
  }

  async fakeSub(id: number) {
    const result = await http.post(`${this.adminPrefix}/${id}/fake-sub`);
    return result.data.result;
  }
}

export default new UserService();
