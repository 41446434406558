import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.less';
import initStore from './stores';
import { Provider } from 'mobx-react';
import './i18n';
import 'mobx-react-lite/batchingForReactDom';
import { BrowserRouter } from 'react-router-dom';
import Loading from './components/commons/Loading';

const stores = initStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider {...stores}>
      <React.Suspense fallback={<Loading />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
