import { observable, action } from 'mobx';
import authenticateService from '@/apis/authenticate.service';
import React from 'react';
import {
  saveToStorage,
  saveToSession,
  removeFromStorage,
  removeFromSession,
} from '@/utils/storage.util';
import { LoginRequestDto } from '@/dto/users/LoginRequest.dto';
import userService from '@/apis/user.service';
import ChangePassword from '@/dto/users/ChangePassword.dto';
import { ResetPassword } from '@/dto/users/ResetPassword.dto';
import firebaseUtil from '@/utils/firebase.util';
import { message } from 'antd';
import { ForgotPasswordDto } from '@/dto/users/ForgotPassword.dto';

export default class AuthenticationStore {
  @observable loggedUser: any = null;
  @observable isAutoplayOn: boolean = false;
  @observable loginFormValue: LoginRequestDto = {
    email: '',
    password: '',
  };

  @action
  async validateGoogleUser(token: string, history: any) {
    const data = await authenticateService.checkGoogleToken(token);
    this.setCurrentInfo(data);
    if (
      this.loggedUser?.userType === null &&
      this.loggedUser?.accountType === 1
    ) {
      return history.push('/account/register');
    } else if (this.loggedUser?.userType === null) {
      return history.push('/account/register');
    }
    return history.push('/account');
  }

  @action
  async login(history: any) {
    const data = await authenticateService.login(this.loginFormValue);
    this.setCurrentInfo(data);
    this.redirectAfterLogin(history);
  }

  @action
  async validateToken(token: string, history: any) {
    const data = await authenticateService.validateToken(token);
    this.setCurrentInfo(data);
    // this.redirectAfterLogin(history);
  }

  _ggLogout(history: any) {
    const myWindow = window as any;
    const _this = this;
    myWindow.gapi.load('auth2', function () {
      myWindow.gapi.auth2
        .init({
          client_id: `${process.env.REACT_APP_GG_ID}.apps.googleusercontent.com`,
        })
        .then(() => {
          const auth2 = myWindow.gapi.auth2.getAuthInstance();
          auth2.signOut().then(() => {
            _this.loggedUser = null;
            history.push('/account/login');
          });
        });
    });
  }

  @action
  async logout(history: any) {
    removeFromStorage('token');
    removeFromSession('checkSubscription');
    // this._logoutFirebase(history);
    this._ggLogout(history);
  }

  async _logoutFirebase(history: any) {
    await firebaseUtil.logout();
    this.loggedUser = null;
    history.push('/account/login');
  }

  @action
  async verifyEmail(token: string, history: any) {
    await userService.validateEmail(token);
    message.success('Email verified');
    history.push('/account/verified-email');
  }

  @action
  async verifyChangeEmail(token: string, history: any) {
    const data = await userService.verifyChangeEmail(token);
    return data;
  }

  @action
  async requestChangeEmail(email: string) {
    const data = await userService.requestChangeEmail(
      this.loggedUser.id,
      email
    );
    return data;
  }

  @action
  async changePassword(model: ChangePassword) {
    const data = await userService.changePassword(this.loggedUser.id, model);
    return data;
  }

  @action
  unsetLoggedUser() {
    this.loggedUser = null;
  }

  @action
  async forgotPassword(model: ForgotPasswordDto) {
    const data = await authenticateService.forgotPassword(model);
    return data;
  }

  @action
  async resetPassword(model: ResetPassword) {
    const data = await authenticateService.resetPassword(model);
    return data;
  }

  private setCurrentInfo(data: any) {
    this.loggedUser = data;
    this.isAutoplayOn = data.autoPlay;
    saveToStorage('token', data.token);
  }

  private redirectAfterLogin(history: any) {
    if (this.loggedUser.role) {
      return history.push('/admin/user');
    }
    return history.push('/account');
  }

  @action
  async setAutoplay(isAutoplayOn: boolean) {
    this.isAutoplayOn = isAutoplayOn;
    const data = await userService.setAutoplayStatus(isAutoplayOn);
    return data;
  }
}

export const AuthenticationStoreContext = React.createContext(
  new AuthenticationStore()
);
