import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Form, Row, Col, Button, Input, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { handleCheckConfirmationRule } from '@/utils/validation.util';
import { useTranslation } from 'react-i18next';
import { AuthenticationStoreContext } from '@/stores/authentication.store';
import ChangePassword from '@/dto/users/ChangePassword.dto';

interface ComponentProps {
  visible: boolean;
  setVisible: any;
}

export default function ChangePasswordModal(props: ComponentProps) {
  const { visible, setVisible } = props;

  const { t } = useTranslation();

  const authStore = React.useContext(AuthenticationStoreContext);

  const closeModal = () => setVisible(false);

  const handleChangePassword = async (value: any) => {
    const data: ChangePassword = {
      current: value.current,
      password: value.password,
    };
    const result = await authStore.changePassword(data);
    if (result) {
      message.success(t('PASSWORD_CHANGE_SUCCESS'));
      closeModal();
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        footer={null}
        onCancel={closeModal}
        title={t('CHANGE_PASSWORD')}
      >
        <Form
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          name="normal_login"
          className="login-form"
          onFinish={(value) => handleChangePassword(value)}
          scrollToFirstError
        >
          <Form.Item
            name="current"
            label={t('CURRENT_PASSWORD')}
            rules={[
              {
                required: true,
                message: t('REQUIRED_FIELD', { field: t('CURRENT_PASSWORD') }),
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Password Confirmation"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please input your Password Confirmation!',
              },
              (rule) => handleCheckConfirmationRule(rule, 'password'),
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password Confirmation"
            />
          </Form.Item>

          <Row justify="end">
            <Col span={4}>
              <Button
                type="primary"
                htmlType="button"
                danger
                onClick={closeModal}
              >
                Close
              </Button>
            </Col>

            <Col span={4}>
              <Button type="primary" htmlType="submit">
                {t('CONFIRM')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
