import React from 'react';
import { observer } from 'mobx-react';
import { StripeStoreContext } from '@/stores/stripe.store';
import { Radio, Form, Checkbox, Button, message } from 'antd';
import Icon from '@ant-design/icons';
import SimpleBar from 'simplebar-react';
import { DeleteSvg } from '@/constants/icons.constants';
import 'simplebar/dist/simplebar.min.css';

interface ComponentProps {
  currentValue?: string;
  setValue?: any;
  visible?: boolean;
  setVisible: any;
}

const CardListing = (props: ComponentProps) => {
  const { currentValue, setValue, visible, setVisible } = props;
  const stripeStore = React.useContext(StripeStoreContext);

  const DeleteIcon = (props: any) => <Icon component={DeleteSvg} {...props} />;

  const handleDelete = async (id: string) => {
    const result = await stripeStore.deletePaymentMethod(id);
    if (result) {
      message.success('Payment method is deleted');
      stripeStore.getPaymentMethods();
    }
  };

  return stripeStore.payments.length > 0 ? (
    <>
      <Form.Item>
        <Checkbox
          className="normal-checkbox"
          onChange={() => {
            if (!visible) {
              setValue('');
            }
            setVisible(!visible);
          }}
        >
          Use Existing Cards
        </Checkbox>
      </Form.Item>
      <Form.Item className={`${visible ? '' : 'hidden'}`}>
        <SimpleBar style={{ maxHeight: 130 }}>
          <Radio.Group
            onChange={(event) => setValue(event.target.value)}
            value={currentValue}
            className="area-full"
          >
            {stripeStore.payments.map((item) => {
              return (
                <div className="radio-item mb-6" key={item.id}>
                  <Radio value={item.id} className="payment-item">
                    <span className="sub-info">xxxxxxxxxxxx</span>
                    {item.last4}
                    <span className="sub-info align-left area-full mb-3">
                      Expiration Date: {item.exp_month}/{item.exp_year}
                    </span>
                    <Button
                      className="btn btn-icon btn-delete"
                      icon={<DeleteIcon />}
                      onClick={() => {
                        handleDelete(item.paymentMethodId);
                      }}
                      title="Delete"
                    />
                  </Radio>
                </div>
              );
            })}
          </Radio.Group>
        </SimpleBar>
      </Form.Item>
    </>
  ) : (
    <></>
  );
};
export default observer(CardListing);
