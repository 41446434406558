import React from 'react';
import { observer } from 'mobx-react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { AuthenticationStoreContext } from '@/stores/authentication.store';
import { CommonStoreContext } from '@/stores/common.store';
import { StripeStoreContext } from '@/stores/stripe.store';
import { Form, message, Typography, Row, Col, Button, Modal } from 'antd';
import LoadingOverlap from '@/components/commons/Loading/Overlap';
import PriceRadioInput from './PriceRadioInput';
import CardListing from './CardListing';
import { getDaysNext } from '@/utils/time.util';

import './index.less';

const { Title, Paragraph } = Typography;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

interface ComponentProps {
  className?: string;
}

const StripeCard = (props: ComponentProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const { className = '' } = props;
  const stripeStore = React.useContext(StripeStoreContext);
  const authStore = React.useContext(AuthenticationStoreContext);
  const commonStore = React.useContext(CommonStoreContext);

  const [price, setPrice] = React.useState<string>('');
  const [payment, setPayment] = React.useState<string>('');
  const [visibleListing, setVisibleListing] = React.useState<boolean>(false);

  React.useEffect(() => {
    stripeStore.getPrices();
    stripeStore.getPaymentMethods();
    setPrice(stripeStore.prices[0]?.id);
  }, [stripeStore]);

  const handleFormFinish = async () => {
    if (!stripe || !elements) return;

    const card = elements.getElement(CardElement);

    if (!price) {
      return message.error('Please choose an price option');
    }

    if (!card) return;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card,
    });

    if (error) {
      return message.error(error.message);
    }

    if (!paymentMethod) {
      return message.error('Error verifying card, Please try again later');
    }

    commonStore.setLoading(true);

    await stripeStore.updatePaymentMethod(
      paymentMethod,
      authStore.loggedUser.id
    );

    const priceItem = stripeStore.prices.find((data) => data.id === price);
    const metadata = {
      subscriptionIntervalUnit: priceItem?.recurring.interval,
      subscriptionIntervalCount: priceItem?.recurring.interval_count,
      trialPeriodDays: priceItem?.recurring.trial_period_days,
    };

    const result = await stripeStore.createSubscription(price, metadata);
    if (result) {
      commonStore.setLoading(false);
      if (result?.statusCode !== 400) {
        showMessageModal(priceItem);
        stripeStore.setCheckSubscription(false);
        stripeStore.getPrices();
        stripeStore.getPaymentMethods();
        stripeStore.getLatestSubscription();
      }
    }
  };

  const showMessageModal = (price: any) => {
    Modal.info({
      className: 'modal-custom modal-info-custom',
      title: '',
      content: (
        <div>
          {`Thank you for subscribing! Your card will be automatically charged on ${getDaysNext(
            7,
            commonStore.shortTimeFormat
          )} after 7 day trial and will be charged every ${
            price?.recurring.interval_count > 1
              ? price?.recurring.interval_count + ' '
              : ' '
          }${price?.recurring.interval}${
            price?.recurring.interval_count > 1 ? 's' : ''
          } until you cancel in your account settings`}
        </div>
      ),
      maskClosable: true,
      okText: 'Home',
      okType: 'default',
      onOk() {
        commonStore.setLoading(false);
      },
      width: '555px',
    });
  };

  const handleSubscribeNow = async () => {
    const paymentData = stripeStore.payments.find(
      (item) => item.id === payment
    );

    if (paymentData) {
      commonStore.setLoading(true);
      await stripeStore.updatePaymentMethod(
        {
          id: paymentData.paymentMethodId,
          card: {
            last4: paymentData.last4,
            exp_month: paymentData.exp_month,
            exp_year: paymentData.exp_year,
          },
        },
        authStore.loggedUser.id
      );

      const priceItem = stripeStore.prices.find((data) => data.id === price);
      const metadata = {
        subscriptionIntervalUnit: priceItem?.recurring.interval,
        subscriptionIntervalCount: priceItem?.recurring.interval_count,
        trialPeriodDays: priceItem?.recurring.trial_period_days,
      };

      const result = await stripeStore.createSubscription(price, metadata);

      if (result) {
        commonStore.setLoading(false);
        if (result?.statusCode !== 400) {
          showMessageModal(priceItem ? priceItem : null);
          stripeStore.setCheckSubscription(false);
          stripeStore.getPrices();
          stripeStore.getPaymentMethods();
          stripeStore.getLatestSubscription();
        }
      }
    }
  };

  return (
    <>
      <Form
        className={`login-form ${className}`}
        onFinish={() => {
          handleFormFinish();
        }}
      >
        <Typography>
          <Title level={3} className="sub-title txt-center mb-6">
            Please subscribe to continue using the app after your 7 day free
            trial ends.
          </Title>
        </Typography>
        <PriceRadioInput currentValue={price} setValue={setPrice} />
        <Typography className="mb-5">
          <Paragraph>
            Your card will be automatically charged after the 7 day trial period
            every subscription period until it is cancelled.
          </Paragraph>
        </Typography>
        <Form.Item className="mb-6">
          <CardElement id="card-element" options={CARD_ELEMENT_OPTIONS} />
        </Form.Item>
        <CardListing
          currentValue={payment}
          setValue={setPayment}
          visible={visibleListing}
          setVisible={setVisibleListing}
        />
        <Row justify="end" className="area-full mt-6">
          <Col span={24} className="modal-actions">
            {visibleListing && payment ? (
              <Button
                type="primary"
                className="btn-full"
                onClick={() => {
                  handleSubscribeNow();
                }}
              >
                <span>Subscribe Now</span>
                <i className="icon"></i>
              </Button>
            ) : (
              <Button type="primary" className="btn-full" htmlType="submit">
                <span>Subscribe Now</span>
                <i className="icon"></i>
              </Button>
            )}
          </Col>
        </Row>
      </Form>
      {commonStore.isLoading && <LoadingOverlap />}
    </>
  );
};
export default observer(StripeCard);
