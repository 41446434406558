import React from 'react';
import { Layout, Menu } from 'antd';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom';
import { adminRoutes } from '@/components/routers/routes';
import './index.less';
import { SettingFilled, LogoutOutlined, LockFilled } from '@ant-design/icons';
import { AuthenticationStoreContext } from '@/stores/authentication.store';
import ChangePasswordModal from '@/components/accounts/ChangePasswordModal';

const { Header, Content, Sider } = Layout;

const AdminLayout = () => {
  const [
    collapseStatusSider,
    changeCollapseStatusSider,
  ] = React.useState<boolean>(false);

  const menuItems = adminRoutes.filter((route) => route.isMenu);
  const history = useHistory();
  const location = useLocation();

  const [
    changePasswordModalVisible,
    setChangePasswordModal,
  ] = React.useState<boolean>(false);

  const authStore = React.useContext(AuthenticationStoreContext);

  const logout = async () => {
    authStore.logout(history);
  };

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          theme="light"
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
          collapsible
          collapsed={collapseStatusSider}
          onCollapse={changeCollapseStatusSider}
        >
          <div className="logo"></div>
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={[location.pathname]}
            forceSubMenuRender
          >
            {menuItems.map((item) => (
              <Menu.Item
                key={item.path}
                icon={item.icon}
                onClick={() => history.push(item.path)}
              >
                {item.title}
              </Menu.Item>
            ))}

            <Menu.Divider />
            <Menu.SubMenu
              key="subMenuUser"
              icon={<SettingFilled />}
              title="User"
              className="submenu-user"
            >
              <Menu.Item
                onClick={() => setChangePasswordModal(true)}
                icon={<LockFilled />}
              >
                Change Password
              </Menu.Item>

              <Menu.Item onClick={logout} icon={<LogoutOutlined />}>
                Logout
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </Sider>
        <Layout
          className="site-layout"
          style={{ marginLeft: collapseStatusSider ? 80 : 200 }}
        >
          <Header className="site-layout-backgroundd">
            <h2 style={{ color: 'white' }}>The Llama App</h2>
          </Header>
          <Content className="main-content">
            <Switch>
              {adminRoutes
                .filter((item: any) => !item.isLayout)
                .map((item: any, index: number) => {
                  return (
                    <Route
                      key={item.path}
                      path={item.path}
                      component={item.component}
                      exact={item.exact}
                    />
                  );
                })}
              <Redirect to="/admin/user" />
            </Switch>
          </Content>
        </Layout>
      </Layout>
      <ChangePasswordModal
        visible={changePasswordModalVisible}
        setVisible={setChangePasswordModal}
      />
    </>
  );
};

export default AdminLayout;
