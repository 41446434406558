import videoService from '@/apis/video.service';
import CreateVideoDto from '@/dto/videos/CreateVideo.dto';
import { observable, action } from 'mobx';
import { createContext } from 'react';

export default class VideoStore {
  @observable adminVideos: any[] = [];
  @observable adminTotalCount: number = 0;
  @observable userVideos: any[] = [];
  @observable initUserVideos: any[] = [];
  @observable featureVideo: any;
  @observable playingVideo: any;
  @observable preview: any;

  initVideo: CreateVideoDto = {
    link: '',
    name: '',
    description: '',
  };

  @action
  async adminGetVideos(query: any) {
    const [videos, totalCount] = await videoService.adminGetVideos(query);

    this.adminVideos = videos;
    this.adminTotalCount = totalCount;
  }

  @action
  async createVideo(model: CreateVideoDto) {
    await videoService.createVideo(model);
    return true;
  }

  @action
  async updateIsActive(id: string, isActive: boolean) {
    await videoService.updateIsActive(id, isActive);
    return true;
  }

  @action
  async updateIsFeatured(id: string, isFeatured: boolean) {
    await videoService.updateIsFeatured(id, isFeatured);
    return true;
  }

  @action
  async deleteVideo(id: string) {
    await videoService.delete(id);
    return true;
  }

  @action
  async updateVideo(id: string, model: CreateVideoDto) {
    await videoService.update(id, model);
    return true;
  }

  @action
  async userGetVideos() {
    const data = await videoService.userGetVideos();
    this.userVideos = data;
  }

  @action
  async userGetInitVideos() {
    const data = await videoService.userGetInitVideos();
    this.featureVideo = data.feature;
    this.initUserVideos = data.videos;
  }

  @action
  async userGetOneVideo(id: string) {
    const video = await videoService.userGetOneVideo(id);
    this.playingVideo = video;
  }

  @action
  async setPlayingVideoNull() {
    this.playingVideo = null;
  }
}

export const VideoStoreContext = createContext(new VideoStore());
