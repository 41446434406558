import React from 'react';
import { observer } from 'mobx-react';
import { StripeStoreContext } from '@/stores/stripe.store';
import { Radio } from 'antd';

interface ComponentProps {
  currentValue: string;
  setValue: any;
}

const PriceRadioInput = (props: ComponentProps) => {
  const { currentValue, setValue } = props;
  const stripeStore = React.useContext(StripeStoreContext);

  return (
    <>
      <Radio.Group
        onChange={(event) => setValue(event.target.value)}
        value={currentValue}
        className="area-full"
      >
        {stripeStore.prices.map((price) => {
          return (
            <div className="radio-item mb-6" key={price.id}>
              <Radio value={price.id}>
                ${price.unit_amount / 100}{' '}
                <span className="sub-info">
                  per{' '}
                  {price.recurring.interval_count > 1
                    ? price.recurring.interval_count
                    : ''}{' '}
                  {price.recurring.interval}
                </span>
              </Radio>
            </div>
          );
        })}
      </Radio.Group>
    </>
  );
};
export default observer(PriceRadioInput);
